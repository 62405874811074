import { ShowAlert } from "../../../GlobalComponent/Alerts/Alert";
import { createAmbulanceProviders, createAmbulanceProvidersCities, createRateCard, deleteAmbulanceProvider, deleteAmbulanceProviderRateCards, deleteProviderFromAllCityById, getLatLon, getRateCardsByProviderId, removeProviderFromCity, updateAmbulanceProviders, updateAmbulanceRateCard } from "../../../Http/Index";
import { AmbulanceCitiesProps } from "../../AmbulanceCities/AmbulanceCities";
import  { AmbulanceRateCardProps } from "../../AmbulanceRateCard/AmbulanceRateCard";
import { AmbulanceProviderInterface } from "../AmbulanceProvidersTsx/AmbulanceProviders";

export const getGeoCode = async (addressText: string) => {
    let payload = {
        addressText
    }
    try {
        const res = await getLatLon(payload);
        const data = res.data;
        if (data.results && data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            return { lat, lng };
        } else {
            throw new Error('No results found');
        }

    } catch (e) {
        ShowAlert("warning", "Error from getting lat and lon for address");
    }
}

export const createAmbulanceProvider = async (payload: AmbulanceProviderInterface) => {
    try {
        const res = await createAmbulanceProviders(payload);
        return res?.data;
    } catch (err) {
        console.log('err:', err);
        ShowAlert("warning", "Error creating ambulance provider");
    }
}

export const updateAmbulanceProviderData = async (providerId: string, payload: AmbulanceProviderInterface) => {
    try {
        const res = await updateAmbulanceProviders(providerId, payload);
        return res?.data;
    } catch (err) {
        console.log('err:', err);
        ShowAlert("warning", "Error updating ambulance provider");
    }
}

export const getRateCardByAmbulanceProviderId = async (payload:any) => {

    try {
        const res = await getRateCardsByProviderId(payload);
        return res?.data.data;
    } catch (err) {
        console.log('err:', err);
        ShowAlert("warning", "Error getting ambulance rate card");
    }
}

export const createAmbulanceRateCard = async (payload: AmbulanceRateCardProps) => {
    try {
        const res = await createRateCard(payload);
        return res?.data;
    } catch (err) {
        console.log('err:', err)
        ShowAlert("warning", "Error creating ambulance provider");
    }
}

export const deleteProviderAndRateCard=async (providerId:string)=>{
    try{
        const res=await deleteAmbulanceProvider(providerId)
        const rateCardRes=await deleteRateCard(providerId);
        const removeProviderFromCity=await removeProviderFromAllCityById(providerId);
        console.log('res:', res,rateCardRes,removeProviderFromCity);
        if(res.data && rateCardRes){

            return {res:res?.data,rateCardRes,removeProviderFromCity};
        }
    }catch(err:any){
        // console.log('err:-----------------', err);
        if( err?.response?.data?.statusCode===404){
            return
        }

        ShowAlert("warning", err?.response?.data?.message||"Delete ambulance provider");

    }
}


export const deleteRateCard=async (providerId:string)=>{
   
        const res=await deleteAmbulanceProviderRateCards(providerId)
   if(res) return res.data
    
}


export const updateRateCard=async (rateCardId:string,payload:any)=>{
    try {
        const res=await updateAmbulanceRateCard(rateCardId,payload);
        return res
        
    } catch (error) {
        console.log('err:', error);
        ShowAlert("warning", "Delete ambulance provider");
    }
}


export const createAmbulanceCities=async(citiesPayload:AmbulanceCitiesProps)=>{
    try {
        const res=await createAmbulanceProvidersCities(citiesPayload)
         return res.data
    } catch (error) {
        console.log('err:', error);
        ShowAlert("warning", "Adding cities to provider");
    }
}


export const removeProviderByCity=async({city,providerId}:{providerId:string,city:string})=>{
    console.log('city:', city,providerId);
    try {
        const res=await removeProviderFromCity({providerId,city});
         return res.data
    } catch (error:any) {
        if(error.response.data.statusCode===404){
            return
        }
        console.log('err:', error);
        ShowAlert("warning", "Removing provider from cities");
    }
};


export const removeProviderFromAllCityById=async(providerId:string)=>{
    console.log('providerId:', providerId)
        const res=await deleteProviderFromAllCityById(providerId);
        console.log('res:', res);
       if(res)  return res.data;
   
}