import {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import { getCompanyList } from '../../Pages/BenAddressData/Action/getAllCompany';
const CompanyContext= createContext<any>(null);
export const CompanyProvider = ({children}: { children: ReactNode }) => {
    const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCompanies = async () => {
    setLoading(true);
    try {
        const getCompany = await getCompanyList();
   
      setCompany(getCompany);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(()=>{
    fetchCompanies();
  },[])
  return (
    <CompanyContext.Provider value={{ company, fetchCompanies, loading }}>
      {children}
    </CompanyContext.Provider>
  );
}

export const useCompany = () => useContext(CompanyContext);
