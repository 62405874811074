import { Table ,DatePicker} from 'antd'
import moment from 'moment';

const { RangePicker } = DatePicker;
function CustomTable( {data,companyList}:any) {
  const columns = [
    {
      title: 'Company Name',
      dataIndex: ["_id", "company"],
      key: 'company',
      filters:companyList?.map((companyData:any)=>({
        text:companyData.companyName,
        value:companyData.companyName
      })),
      onFilter: (value:any, record:any) => record._id.company === value,
    },
    {
      title: 'Ticket Status',
      dataIndex: ["_id", "status"],
      key: 'status',
      filters: [
        { text: 'New', value: 'New' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Cancelled', value: 'Cancelled' },
        { text: 'Customer Test', value: 'Customer Test' },
        { text: 'Internal Test', value: 'Internal Test' },
        { text: 'Work in progress', value: 'Work in progress' },
        // Add more options dynamically if needed
      ],
      onFilter: (value:any, record:any) => record._id.status === value,
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: "Date Filter",
      dataIndex: "date",
      key: "date",
      filterDropdown: ({ setSelectedKeys, confirm,clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            onChange={(dates:any) => {
              if (!dates) {          
               
                setSelectedKeys([]); // Clear Ant Design filter keys
                clearFilters(); // Clear table filters
                confirm(); // Trigger table re-render
              } else {
                const formattedDates = dates.map((date:any) => date.format('YYYY-MM-DD'))
                setSelectedKeys([formattedDates]); // Update filter keys
              }
            }}
            style={{ width: "100%" }}
            allowClear 
          />
          <div style={{ marginTop: 8, textAlign: "right" }}>
            <button
              onClick={() => confirm()} // Apply the filter
              style={{ marginRight: 8 }}
            >
              OK
            </button>
          </div>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        if (!value || !value.length) return true;
    
        const recordDate = moment(record.date).startOf('day'); // Normalize record date to start of day
        const startDate = moment(value[0]).startOf('day'); // Normalize start date to start of day
        const endDate = moment(value[1]).endOf('day'); // Normalize end date to start of day
        console.log('startDate:', startDate,endDate)
        // Check if the dates are valid
        if (!recordDate.isValid() || !startDate.isValid() || !endDate.isValid()) {
          console.error('Invalid dates:', { recordDate, startDate, endDate });
          return false; // If any of the dates is invalid, don't filter
        }
    
        console.log('startDate:', startDate.format('YYYY-MM-DD'), 'endDate:', endDate.format('YYYY-MM-DD'), 'recordDate:', recordDate.format('YYYY-MM-DD'));
    
        // Check if the recordDate is within the selected date range
        return recordDate.isBetween(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), 'days', '[]');
      },
      render: (text: string) => moment(text).format("YYYY-MM-DD"),
    }
    
  ];
  return (
    <div>
      <Table
      dataSource={data}
      columns={columns}
      rowKey={(record:any) => `${record._id.company}-${record._id.status}`}
    />
    </div>
  )
}

export default CustomTable
