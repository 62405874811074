import { useEffect, useState } from "react"
import { Layout } from "../../../GlobalComponent/Layout/Layout"
import AmbulanceServiceFormStepOne from "../StepOneForm/AmbulanceServiceFormStepOne"
import { getAmbulanceProviders } from "../../../Http/Index"
import { createAmbulanceProvider, getGeoCode, updateAmbulanceProviderData } from "../Action/action"
import { ShowAlert } from "../../../GlobalComponent/Alerts/Alert"
import AmbulanceRateCard from "../../AmbulanceRateCard/AmbulanceRateCard"
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import AmbulanceCities from "../../AmbulanceCities/AmbulanceCities"

export interface AmbulanceProviderInterface {
    name: string,
    address: {
        addressLine1: string,
        city: string,
        pinCode: string,
        country: string,
        state: string,
        location: {
            latitude: number,
            longitude: number
        },

    },
    contact: {
        mobile: [{ countryCode: string, number: string }],
        email?: string[];
    }

}

export const AmbulanceProviders = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [provider, setProvider] = useState([])
    const [clearFormOne, setClearFormOne] = useState<Boolean>(false);
    const [clearFormTwo, setClearFormTwo] = useState<Boolean>(false);
    const [clearFormThree, setClearFormThree] = useState<Boolean>(false);
    const handleTabChange = (index:number) => {
        setSelectedTab(index);
        setClearFormOne(!clearFormOne);  
        setClearFormTwo(!clearFormTwo);
        setClearFormThree(!clearFormThree);
    }
    const getAllAmbulanceProviders = async () => {
        const result = await getAmbulanceProviders();
        setProvider((result?.data?.data) || [])
        console.log('result:', result);

    }

    const handleCollectData = async (data: any, handleClear: any, action: string) => {
        ////first need to get lat lon using these address google api before creating provider//
        //then using those lat lon need to update the payload and need to crete providers//
        ////
        const payload: { addressLine1: string, city: string, state: string, pinCode: string, country: string } = {
            addressLine1: data.addressLine1 || "",
            city: data.city,
            state: data.state,
            country: data.country,
            pinCode: data.pinCode
        }
        const addressText = `${data.dataLine1}${data.city} ${data.state} ${data.pinCode}`;
        const response = await getGeoCode(addressText);
        if (response) {
            const updatedAddressLocation = {
                ...payload,
                location: {
                    latitude: Number(response?.lat),
                    longitude: Number(response?.lng),
                },
            };
           console.log(' data?.emails:', data);
            const payloadBuilder: AmbulanceProviderInterface = {
                name: data.name,
                address: { ...updatedAddressLocation },
                contact: {
                    mobile: data.contacts,
                    ...(data?.emails?.length?{email: data.emails}:{email:[]})
                }
            };
            if (action === "create") {
                await handleCreateAmbulanceProvider(payloadBuilder);
                clearFormData(handleClear)
            } else {
                // alert("check");
               console.log(' data?.providerId, payloadBuilder:',  data?.providerId, payloadBuilder);
                await updateAmbulanceProvider(data?.providerId, payloadBuilder)
                clearFormData(handleClear);
            };
        } else {
            ShowAlert("warning", "someTechnical storing Location try again");
        }
    }

    const handleCreateAmbulanceProvider = async (ProviderPayload: any) => {
        const res = await createAmbulanceProvider(ProviderPayload);
        if (res) {
            ShowAlert("success", "Ambulance provider created  successfully");
            //need to move next step 
            //rate card
        }
    }
    const updateAmbulanceProvider = async (providerId: string, ProviderPayload: any) => {
        const res = await updateAmbulanceProviderData(providerId, ProviderPayload);
        if (res) {
            ShowAlert("success", "Ambulance provider updated  successfully");
            //need to move next step 
            //rate card
        }
    }

    const clearFormData = (handleClear: any) => {
        getAllAmbulanceProviders();
        handleClear()
    }
    const deleteProvider=()=>{
        getAllAmbulanceProviders();
    };

  
    useEffect(() => {
        getAllAmbulanceProviders()
    }, [])
    // console.log('provider:', provider);

    return <>
        <Layout>
            <Box style={{width:"100%",height:"50px"}}>
                <Tabs index={selectedTab} onChange={handleTabChange} variant="soft-rounded" colorScheme="green">
                <TabList>
                    <Tab>Ambulance Provider</Tab>
                    <Tab>Ambulance RateCard</Tab>
                    <Tab>Ambulance City</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <AmbulanceServiceFormStepOne clearFormOne={clearFormOne} setClearFormOne={setClearFormOne} providers={provider} onNext={handleCollectData} deleteProvider={deleteProvider}  />
                    </TabPanel>
                    <TabPanel>
                        <AmbulanceRateCard providers={provider} getAllAmbulanceProviders={getAllAmbulanceProviders}  clearFormTwo={clearFormTwo} setClearFormTwo={setClearFormTwo} />
                    </TabPanel>
                    <TabPanel>
                        <AmbulanceCities  providers={provider} clearFormThree={clearFormThree} setClearFormThree={setClearFormThree} />
                    </TabPanel>
                </TabPanels>

                </Tabs>
                
            </Box>
        </Layout>
    </>

}