import { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, VStack, HStack, Spinner, Flex } from '@chakra-ui/react';
import { createAmbulanceCities, removeProviderByCity, removeProviderFromAllCityById } from '../AmbulanceProviders/Action/action';
import { getAmbulanceProvidersCities } from '../../Http/Index';
import { ShowAlert } from '../../GlobalComponent/Alerts/Alert';
import { default as ReactSelectDropdown } from 'react-select';
export interface CityProps {
    city: string;
    state: string;
}

export interface ProviderCityProps {
    providerId: string;
    cities: CityProps[];
}

export interface AmbulanceCitiesProps {
    providers: ProviderCityProps[];
};
const options = [
    { value: 'andhra-pradesh', label: 'Andhra Pradesh' },
    { value: 'arunachal-pradesh', label: 'Arunachal Pradesh' },
    { value: 'assam', label: 'Assam' },
    { value: 'bihar', label: 'Bihar' },
    { value: 'chhattisgarh', label: 'Chhattisgarh' },
    { value: 'goa', label: 'Goa' },
    { value: 'gujarat', label: 'Gujarat' },
    { value: 'haryana', label: 'Haryana' },
    { value: 'himachal-pradesh', label: 'Himachal Pradesh' },
    { value: 'jharkhand', label: 'Jharkhand' },
    { value: 'karnataka', label: 'Karnataka' },
    { value: 'kerala', label: 'Kerala' },
    { value: 'madhya-pradesh', label: 'Madhya Pradesh' },
    { value: 'maharashtra', label: 'Maharashtra' },
    { value: 'manipur', label: 'Manipur' },
    { value: 'meghalaya', label: 'Meghalaya' },
    { value: 'mizoram', label: 'Mizoram' },
    { value: 'nagaland', label: 'Nagaland' },
    { value: 'odisha', label: 'Odisha' },
    { value: 'punjab', label: 'Punjab' },
    { value: 'rajasthan', label: 'Rajasthan' },
    { value: 'sikkim', label: 'Sikkim' },
    { value: 'tamil-nadu', label: 'Tamil Nadu' },
    { value: 'telangana', label: 'Telangana' },
    { value: 'tripura', label: 'Tripura' },
    { value: 'uttar-pradesh', label: 'Uttar Pradesh' },
    { value: 'uttarakhand', label: 'Uttarakhand' },
    { value: 'west-bengal', label: 'West Bengal' },
    { value: 'andaman-and-nicobar', label: 'Andaman and Nicobar Islands' },
    { value: 'chandigarh', label: 'Chandigarh' },
    { value: 'dadra-and-nagar-haveli', label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { value: 'lakshadweep', label: 'Lakshadweep' },
    { value: 'delhi', label: 'Delhi' },
    { value: 'puducherry', label: 'Puducherry' }
];

function AmbulanceCities({ clearFormThree, setClearFormThree, providers: AmbulanceProviders }: any) {
    // const [AmbulanceProviders, setAmbulanceProviders] = useState([]);
    const [cityErrors, setCityErrors] = useState<{ cityError: string; stateError: string }[]>([]);
    console.log('providers:', AmbulanceProviders);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedProviderCities, setSelectedProviderCities] = useState<any>()
    const [formValues, setFormValues] = useState({
        ambulanceProviderId: '',
        ambulanceProviderName: '',

    });
    const [cities, setCities] = useState<any>([
        { city: '', state: '' },
    ]);
  
    const plainText = (text: string) => {
        return text?.toLowerCase().replace(/[\s-]+/g, '')
    }

    useEffect(() => {
        fetchRateCards();
        //eslint-disable-next-line
    }, [formValues.ambulanceProviderId]);

    useEffect(() => {
        // console.log('selectedProviderCities:', selectedProviderCities);
        const existingCityDetails = selectedProviderCities?.map((provider: any) => ({
            city: plainText(provider?.city),
            state: plainText(provider?.state)
        }));
        // console.log('existingCityDetails:', existingCityDetails)
        // alert("cehck");
        setCities(existingCityDetails?.length ? existingCityDetails : cities);
        // set_Dub_Cities(existingCityDetails?.length ? existingCityDetails : cities);
        setIsLoading(false);
        //eslint-disable-next-line
    }, [selectedProviderCities])
    const fetchRateCards = async () => {
        setCities([{ city: '', state: '' }]);
        if (formValues.ambulanceProviderId) {
            try {
                setIsLoading(true);
                const ambulanceCities = await getAmbulanceProvidersCities(formValues.ambulanceProviderId);
                console.log('ambulanceCities:', ambulanceCities);
                setSelectedProviderCities(ambulanceCities?.data?.data);
            } catch (error) {
                console.error('Error fetching cities:', error);
                setIsLoading(false);
            }
        }
    };

    // Handle provider selection
    const handleProviderChange = (e: any) => {
        console.log('e.target.value:', e.target.value.length);
        if (e.target.value.length === 24) {
            const selectedProvider: any = AmbulanceProviders.find((provider: any) => provider._id === e.target.value);
            setFormValues({
                ...formValues,
                ambulanceProviderId: selectedProvider?._id,
                ambulanceProviderName: selectedProvider?.name,
            });
        } else {
            setFormValues({
                ambulanceProviderId: '',
                ambulanceProviderName: '',
            });
            setCities([
                { city: '', state: "" },]);
        }
    };



    // Handle rate card changes
    const handleCityChange = (index: number, field: string, value: string) => {
        // Clone the cities and errors state
        const updatedCities: any = [...cities];
        const updatedErrors = [...cityErrors];
    
        // Get the initial city-state details of the selected provider
        const existingCityDetails = selectedProviderCities?.map((provider: any) => ({
            city: plainText(provider?.city),
            state: plainText(provider?.state),
        }));
    
        console.log("existingCityDetails:", existingCityDetails);
    
        // Check for invalid edits
        const isInvalidEdit = existingCityDetails.some(
            (item: any) =>
                item.city === updatedCities[index]?.city &&
                field === "state" &&
                value !== item.state
        );
    
        if (isInvalidEdit) {
            // Show an alert
            ShowAlert("warning", "Cannot modify existing city-state combinations. Only add or remove.");    
            // Revert the field to its previous value
            updatedCities[index][field] = cities[index][field];
        } else {
            // Update the value if no invalid edit
            updatedCities[index][field] = value;
    
            // Update errors
            if (field === "city") {
                updatedErrors[index] = {
                    ...updatedErrors[index],
                    cityError: value ? "" : "City is required",
                };
            }
            if (field === "state") {
                updatedErrors[index] = {
                    ...updatedErrors[index],
                    stateError: value ? "" : "State is required",
                };
            }
        }
    
        // Update the state
        setCities(updatedCities);
        setCityErrors(updatedErrors);
    };
    

    // Add a new rate card
    const handleAddCities = () => {

        setCities([{ city: '', state: '' }, ...cities,]);
        setCityErrors([{ cityError: '', stateError: '' }, ...cityErrors]);
    };

    // Remove a rate card
    const handleRemoveCities = async (index: number) => {
        //two case remove cities from list first need to check that city already in the selected Provider if it their that means we are going to delete 
        //if it is not their then just need to update from the setCities locally 
        // console.log('selectedProviderCities:-1', selectedProviderCities);
        const getCity = cities.filter((_:any, i:any) => i === index);
        // console.log('getCity:-2', getCity);
        const findExistingCityList = selectedProviderCities.filter((data: any) => getCity[0].city.toLowerCase() === data?.city?.toLowerCase());
        // console.log('findExistingCityList:-3', findExistingCityList)
        const removeCity = cities.filter((_:any, i:any) => i !== index)
        // setRemovedCities(removeCity);
        setCities(removeCity);
        if (findExistingCityList.length) {
            ///we need to send that city and provider id to delete
            await removeProviderByCity({ providerId: formValues.ambulanceProviderId, city: findExistingCityList[0].city });
            ShowAlert("success", "Provider has been removed from the city");
        };
    };



    // Submit handler
    const handleSubmit = async () => {
        const formData = { ...formValues, cities };
        // console.log('cities:', cities);
        if (!formData.ambulanceProviderId || !formData.ambulanceProviderName) {
            return ShowAlert("warning", "Select provider");
        } else if (!formData?.cities?.length) {
            return ShowAlert("warning", "Add Cities");
        };
        const errors = cities.map((city:any) => ({
            cityError: !city.city ? "City is required" : "",
            stateError: !city.state ? "State is required" : "",
        }));
        setCityErrors(errors);
        const hasErrors = errors.some((error:any) => error.cityError || error.stateError);
        if (hasErrors) {
            return; // Prevent submission if errors exist
        }
        let providerPayload: any = {
            providers: []

        };

        const removeUnusedCities = cities.filter((data: any) => data.city && data.state);
        // console.log('removeUnusedCities:', removeUnusedCities)
        providerPayload.providers.push({ providerId: formData.ambulanceProviderId, cities: removeUnusedCities })

        const res = await createAmbulanceCities(providerPayload);
        if (res) {
            ShowAlert("success", "Cities added successfully");
            fetchRateCards();
            // handleClearCities();

        }

    };
    const handleRemoveAllCities = async () => {
        if (!formValues.ambulanceProviderId) {
            ShowAlert("warning", "Please select a provider first");
            return;
        }

        try {
            setIsLoading(true);
            await removeProviderFromAllCityById(formValues.ambulanceProviderId);
            ShowAlert("success", "these may take some time please refresh sometime back");
            setCities([{ city: '', state: '' }]); // Clear cities state
        } catch (error) {
            console.error("Error removing all cities:", error);
            ShowAlert("error", "Failed to remove all cities");
        } finally {
            setIsLoading(false);
        }
    };
    const handleClearCities = () => {
        setFormValues({
            ambulanceProviderId: '',
            ambulanceProviderName: '',
        })
        setCities([
            { city: '', state: "" },]);
        // switchTab(0);
    }

    useEffect(() => {
        // console.log('clearFormTwo:', clearFormTwo);
        if (clearFormThree) {
            handleClearCities()
            setClearFormThree(!clearFormThree)
        }
        return;
        //eslint-disable-next-line
    }, [clearFormThree])
    // console.log('isLoading:', isLoading);

    return (
        <Box display={"flex"} justifyContent={"flex-start"} w={"100%"}>
            <Box p={5} maxW="50%" bg="gray.50" borderRadius="md" boxShadow="md">
                <Box mb={4}>
                    <h2>Ambulance Cities</h2>
                </Box>
                <VStack spacing={4} align="stretch">
                    {/* Provider Dropdown */}
                    <FormControl>
                        <FormLabel>Select Provider</FormLabel>
                        <Select
                            placeholder="Select Provider"
                            onChange={handleProviderChange}
                            value={formValues.ambulanceProviderId}
                        >
                            {AmbulanceProviders && AmbulanceProviders?.map((provider: any) => (
                                <option key={provider._id} value={provider._id}>
                                    {provider.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    {/* Vehicle Type Dropdown */}
                    <Button colorScheme="red" mt={4} onClick={handleRemoveAllCities} isDisabled={!formValues.ambulanceProviderId || cities.length === 0}>
                        Remove All Cities
                    </Button>
                    {/* Rate Cards */}
                    <Button colorScheme="blue" mt={4} onClick={handleAddCities}>
                        Add City
                    </Button>
                    {isLoading ? (<Flex justifyContent={"center"} alignItems={"center"}>
                        <Spinner size="lg" color="blue.500" />
                    </Flex>) : (cities.map((city:any, index:any) => (
                        (city?.city !== undefined && city?.city !== null) &&
                        <Box key={index} p={4} bg="white" borderRadius="md" boxShadow="sm" mt={4}>
                            <HStack spacing={4} alignItems="flex-end">
                                <FormControl isRequired>
                                    <FormLabel>City</FormLabel>
                                    <Input
                                        type="text"
                                        value={city.city}
                                        onChange={(e) => handleCityChange(index, 'city', `${e.target.value}`)}
                                        // disabled={ city?.city ?true:false}
                                        placeholder="e.g Bangalore"
                                    />
                                    {cityErrors[index]?.cityError && (
                                        <Box color="red.500" fontSize="sm">{cityErrors[index].cityError}</Box>
                                    )}
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>State</FormLabel>
                                    {/* <Select
                                        value={city.state}
                                        onChange={(e) => handleCityChange(index, 'state', e.target.value)}
                                    >
                                        <option value="flat">Flat</option>
                                        <option value="per km">Per km</option>
                                    </Select> */}
                                    <ReactSelectDropdown
                                        value={options.find(option => plainText(option.value) === plainText(city.state)) || null}
                                        onChange={(e: any) => handleCityChange(index, 'state', plainText(e.value))} // Use `e.value`
                                        options={options}
                                        //  isDisabled={city.state?true:false}
                                        placeholder="Select State"
                                    />
                                    {cityErrors[index]?.stateError && (
                                        <Box color="red.500" fontSize="sm">{cityErrors[index].stateError}</Box>
                                    )}
                                </FormControl>


                                <Button
                                    colorScheme="red"
                                    width={"40%"}
                                    onClick={() => handleRemoveCities(index)}
                                    disabled={cities.length === 1}
                                >
                                    Remove
                                </Button>
                            </HStack>
                        </Box>
                    )))}
                    {/* Add Rate Card and Submit */}
                    <Button colorScheme="teal" mt={4} onClick={handleSubmit}>
                        Add
                    </Button>
                </VStack>
            </Box>
        </Box>

    );
}

export default AmbulanceCities;

