import { useEffect, useState } from 'react'
import CustomTable from './CustomTable'
import { getTicketData } from '../Action/action';
import { useCompany } from '../../../GlobalComponent/CompanyContext/CompanyContext';

function TicketDashboard() {
    const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(false);
    //eslint-disable-next-line
    const [filters, setFilters] = useState<any>({}); 
    // const [companyData,setCompanyData]=useState([]);
    const {company}=useCompany();


const buildQueryParams=()=>{
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (Array.isArray(filters[key])) {
        filters[key].forEach((value:any) =>
          queryParams.append(`${key}[]`, value)
        );
      } else {
        queryParams.append(key, filters[key]);
      }
    });
}    

const fetchTicketData=async()=>{
    try {  
        const getQuery=buildQueryParams();  
        console.log('getQuery:', getQuery)
        const res=await getTicketData(getQuery);
        // console.log('res:', res);
        setData(res.data);        
    } catch (error) {
        console.log('error:', error);
        
    }
}

useEffect(()=>{
    fetchTicketData();
    //eslint-disable-next-line
},[]);
// useEffect(()=>{
//     fetchTicketData()
// },[filters]);
  return (
    <div>
      <CustomTable companyList={company?.data} data={data}/>
    </div>
  )
}

export default TicketDashboard
