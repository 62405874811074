import axios from "axios";
import apiConfig from "../ApiConfig/apiConfig";

import { ApolloClient, InMemoryCache } from "@apollo/client"
const baseUri = apiConfig.api.baseURL;
// const baseUri = 'http://localhost:3081'
const urls = {
  User_URI: `${baseUri}/auth/api/v1`,
  // User_URI: `http://localhost:3002/api/v1`,
  Ticket_URI: `${baseUri}/ticket/api/v1`,
  OZONETEL_URI: `${baseUri}/ozonetel/api/v1`,
  BENEFICIARY_URI: `${baseUri}/beneficiary/api/v1/beneficiary`,
  // BENEFICIARY_URI: `http://localhost:3001/api/v1/beneficiary`,
  Hospital_URI: `${baseUri}/hospital/api/v1`,
  Whatsapp_URI: `${baseUri}/whatsapp/api/v1`,
  // Whatsapp_URI: `http://localhost:3001/api/v1`,
  AZURE_URI: `${baseUri}/azure/api/v1`,
  SSE_URI: `${baseUri}/ticket/api/v1`,
  CALL_URI: `${baseUri}/call/api/v1`,
  OPS_URI: `${baseUri}/ops/api/v1`,
  GRAPHQL_URI: `${baseUri}/beneficiary/api/v1/graphql`,
  COMPANY_URI: `${baseUri}/company/api/v1/company-service`,
  EXCEL_DOWNLOAD_URI: `${baseUri}/beneficiary/api/v1`,
  // GRAPHQL_URI:`http://localhost:3081/api/v1/graphql`
  EMAIL_URI: `${baseUri}/email/api/v1`,
  // EMAIL_URI: `http://localhost:3032/api/v1`
};

const axiosInstance = axios.create({
  baseURL: "",
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",

  },
});

///api-endpoint/////
/// auth-user-service
export const agentLoginFn = (payload: any) =>
  axiosInstance.post(`${urls.User_URI}/auth/poco-user-login`, payload);

export const adminUserLogout = () => axiosInstance.get(`${urls.User_URI}/auth/poco-admin-user-logout`);


///benService//ops-service//
export const benAddressData = (payload: any) => axiosInstance.get(`${urls.BENEFICIARY_URI}/get-ben-by-filter?benName=${payload.benName}&benMobile=${payload.benMobile}&benEmail=${payload.benEmail}&company=${payload.company}&benType=${payload.subType}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`)
export const printingData = () => axiosInstance.get(`${urls.OPS_URI}/printing`);
export const getSingleJob = (jobid: string) => axiosInstance.get(`${urls.OPS_URI}/printing/${jobid}`);
export const deleteBenFromJob = (jobid: string, id: string) => axiosInstance.delete(`${urls.OPS_URI}/printing/deleteBeneficiary/${jobid}`, { data: { id } });
export const addBenToJob = (jobid: string, payload: any) => axiosInstance.post(`${urls.OPS_URI}/printing/addBeneficiary/${jobid}`, payload);
export const updateBenStatus = (payload: any) => axiosInstance.patch(`${urls.OPS_URI}/printing/updateBenStatus`, payload);
export const updateJobStatus = (jobid: string, payload: any) => axiosInstance.patch(`${urls.OPS_URI}/printing/UpdateJob/${jobid}`, payload);
export const createJob = (payload: any) => axiosInstance.post(`${urls.OPS_URI}/printing`, payload);
export const graphQlPost = (query: any) => axiosInstance.post(`${urls.GRAPHQL_URI}`, query);
export const updateUserByExcel = (payload: any) => axiosInstance.post(`${urls.BENEFICIARY_URI}/update-user-by-excel`, payload)
export const handleExcelSheet = (payload: any) => axiosInstance.post(`${urls.BENEFICIARY_URI}/handle-excel-sheet`, payload)
export const searchQuery = (payload: any) => axiosInstance.get(`${urls.BENEFICIARY_URI}/search-query`, { params: payload })
export const multipleSearchQuery = (payload: any) => axiosInstance.post(`${urls.BENEFICIARY_URI}/multiple-search-query`, { data: payload })
export const downloadBenDashBoardExcel = () => axiosInstance.get(`${urls.EXCEL_DOWNLOAD_URI}/bendata-excel`, { responseType: 'blob', })

//WaBulkMsg///
export const sendBulk = (payload: any) => axiosInstance.post(`${urls.Whatsapp_URI}/send-bulk-msg/send-bulk`, payload);
export const createWaJob = (payload: any) => axiosInstance.post(`${urls.OPS_URI}/whatsapp-bulk-msg`, payload);
export const getAllWaJob = () => axiosInstance.get(`${urls.OPS_URI}/whatsapp-bulk-msg`);
export const getWaJobById = (jobId: string) => axiosInstance.get(`${urls.OPS_URI}/whatsapp-bulk-msg/${jobId}`);
export const updateWaJobById = (jobId: string, payload: any) => axiosInstance.patch(`${urls.OPS_URI}/whatsapp-bulk-msg/${jobId}`, payload);
export const getBenList = (benIds: any) => axiosInstance.post(`${urls.OPS_URI}/whatsapp-bulk-msg/get-ben-list`, benIds);
export const replaceBenWithSubscriber = (jobId: string) => axiosInstance.patch(`${urls.OPS_URI}/whatsapp-bulk-msg/replace-ben-with-subscriber/${jobId}`);

////whatsapp//
export const whatsappConfiguration = () => axiosInstance.get(`${urls.Whatsapp_URI}/send-bulk-msg`);
export const getWaTemplateDetails = (serviceName: string, templateName: string) => axiosInstance.get(`${urls.Whatsapp_URI}/send-bulk-msg/${serviceName}/${templateName}`);
export const sendWhatsappMsg = (payload: any) => axiosInstance.post(`${urls.Whatsapp_URI}/whatsapp`, payload);
export const whatsappMsgDeliveryStatus = (waId: any) => axiosInstance.get(`${urls.Whatsapp_URI}/whatsapp/${waId}`);
///company//
export const getCompany = () => axiosInstance.get(`${urls.COMPANY_URI}/partnerCompanies`)
export const addCompany=(payload:any)=>axiosInstance.post(`${urls.COMPANY_URI}/partnerCompanies`,payload)
// Auth service
export const deleteUser = (payload: any) => axiosInstance.delete(`${urls.User_URI}/user/delete-userdata`, { data: payload });

//ops-service//
export const getDashBoardData = (tag: any, from: any, to: any) => axiosInstance.get(`${urls.OPS_URI}/ben-dashboard/getByDate/${tag || null}/${from || null}/${to || null}`);
///sasToken//
export const generateSasToken = () => {
  return axiosInstance.get(`${urls.AZURE_URI}/signedUrl/regenerateSaSToken`, {
  })
};

//geocode//
export const getLatLon=(payload: any) => {
  return axiosInstance.post(`${urls.AZURE_URI}/google-map/getgeocode`,payload);
}

/// bulkEmail///
export const getAllEmailJobs = () => axiosInstance.get(`${urls.EMAIL_URI}/bulk-email/get-all-jobs`)
export const getBulkEmailTemplates = () => axiosInstance.get(`${urls.EMAIL_URI}/bulk-email/get-bulk-templates`)
export const getSingleEmailJob = (id: string) => axiosInstance.get(`${urls.EMAIL_URI}/bulk-email/get-job/${id}`)
export const createBulkEmailJob = (jobName: string) => axiosInstance.post(`${urls.EMAIL_URI}/bulk-email/create-job/${jobName}`)
export const updateBulkEmailJob = (jobId: string, payload: any) => axiosInstance.patch(`${urls.EMAIL_URI}/bulk-email/update-job/${jobId}`, payload)
export const sendBulkEmail = (templateId: string, jobId: string, payload: Object) => axiosInstance.post(`${urls.EMAIL_URI}/bulk-email/send-bulk-email/${templateId}/${jobId}`, payload)
//AmbulanceProviders_Manage
export const createAmbulanceProviders=(providersPayload:any)=>axiosInstance.post(`${urls.Hospital_URI}/ambulance-provider`,providersPayload);

export const deleteAmbulanceProvider=(providerId:string)=>axiosInstance.delete(`${urls.Hospital_URI}/ambulance-provider/${providerId}`);

export const deleteAmbulanceProviderRateCards=(providerId:string)=>axiosInstance.delete(`${urls.Hospital_URI}/ambulance-rate-card/deleteByProviderId/${providerId}`);

export const createRateCard=(providersRateCardPayload:any)=>axiosInstance.post(`${urls.Hospital_URI}/ambulance-rate-card`,providersRateCardPayload);

export const updateAmbulanceProviders=(providerId:string,payload:any)=>axiosInstance.patch(`${urls.Hospital_URI}/ambulance-provider/${providerId}`,payload);

export const updateAmbulanceRateCard=(rateCardId:string,payload:any)=>axiosInstance.patch(`${urls.Hospital_URI}/ambulance-rate-card/${rateCardId}`,payload);

export const getAmbulanceProviders=()=>axiosInstance.get(`${urls.Hospital_URI}/ambulance-provider`);
///createAndUpdate
export const createAmbulanceProvidersCities=(citiesPayload:any)=>axiosInstance.post(`${urls.Hospital_URI}/ambulance-city`,citiesPayload);

export const getAmbulanceProvidersCities=(providerId:string)=>axiosInstance.get(`${urls.Hospital_URI}/ambulance-city/get-provider?provider_id=${providerId}`);

export const deleteAmbulanceProvidersCities=(providerId:string)=>axiosInstance.delete(`${urls.Hospital_URI}/ambulance-city/get-provider?provider_id=${providerId}`);

export const removeProviderFromCity=({providerId,city}:{providerId:string,city:string})=>axiosInstance.delete(`${urls.Hospital_URI}/ambulance-city/remove-city?city=${city}&providerId=${providerId}`);

export const deleteProviderFromAllCityById=(providerId:string)=>axiosInstance.delete(`${urls.Hospital_URI}/ambulance-city/${providerId}`);


export const getRateCardsById=(providerId:any)=>axiosInstance.get(`${urls.Hospital_URI}/ambulance-rate-card/${providerId}`);
export const getRateCardsByProviderId=(providerPayload:any)=>axiosInstance.post(`${urls.Hospital_URI}/ambulance-rate-card/search`,providerPayload);

export const getTicketDashboardData=(queryParams:any)=>axiosInstance.get(`${urls.Ticket_URI}/ticket/get-ticket-dashboard?${
  queryParams ? queryParams.toString() : ""
}`);
// Searchbyradius;
export const SearchByRadius = (
  companyId: string | null,
  pageSize: number,
  pageNumber: number,
  radius: number
) => {
  const params: Record<string, any> = {
    pageSize,
    currentPage: pageNumber,
    radius
  };

  if (companyId) {
    params.companyId = companyId;
  };
  const queryString = new URLSearchParams(params).toString();
  return axiosInstance.get(`${urls.OPS_URI}/ben-dashboard/without-hospital-report?${queryString}`);
};





// (i)////graphQL
export const client = new ApolloClient({
  uri: `${urls.GRAPHQL_URI}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only', // This ensures that queries always fetch from the network, not from the cache
      errorPolicy: 'all', // This will make sure all errors are handled and won't crash your app
    },
  },
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // console.log(originalRequest,'originalRequest ****************************');
    // console.log(error,'***********');
    // Handle unauthorized errors (status 401)
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        // Attempt to refresh the access token using a POST request to refresh endpoint
        await axios.post(
          `${urls.User_URI}/auth/poco-user-refresh-token`,
          {},
          {
            withCredentials: true, // Ensure credentials are sent with the refresh token request
          }
        );
        // Retry the original request
        return axiosInstance(originalRequest);
      } catch (err) {
        // Handle errors that occur during the refresh token request
        // console.log('err:', err);
        // localStorage.setItem("isLogin", "false");
        // window.location.reload();
        window.location.href = "/login";
        // const localStorageUpdatedEvent = new Event("localStorageUpdated");
        // window.dispatchEvent(localStorageUpdatedEvent);
        // Redirect to login page, show an error message, or handle as appropriate
        // throw err; // Throw the error to indicate that the request failed
      }
      // localStorage.setItem("isLogin", "false");
      window.location.href = "/login";
      // window.location.reload();
    }
    // For other errors, just throw the error to be handled by the caller
    throw error;
  }
);

export default axiosInstance;
